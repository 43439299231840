import React from 'react'
import HomeBlogs from '../Home/HomeBlogs'
import HomeServices from '../Home/HomeServices'

const index = () => {
  return (
    <>
        <HomeServices/>
    </>
  )
}

export default index