import React from 'react'
import HomeBlogs from '../Home/HomeBlogs'

const index = () => {
  return (
    <>
        <HomeBlogs/>
    </>
  )
}

export default index