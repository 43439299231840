import React from 'react'
import HomeProjects from '../Home/HomeProjects'

const index = () => {
  return (
    <>
        <HomeProjects/>
    </>
  )
}

export default index